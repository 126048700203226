import { StatisticsDataResolution } from '@bd/api'
import { getLocalIsoDate } from '@bd/helpers'

export const PERIODS = [1, 3, 6, 12, 'all'] as const

export type Period = typeof PERIODS[number]

export interface PeriodOption {
  months: Period
  label: string
}

export const resolutionForPeriod = (
  period: Period,
): StatisticsDataResolution => {
  return period === 1
    ? StatisticsDataResolution.WEEK
    : StatisticsDataResolution.MONTH
}

export const startDateForPeriod = (period: Period) => {
  if (period === 'all') return undefined
  const now = new Date()
  now.setMonth(now.getMonth() - period)
  return getLocalIsoDate(now)
}
