<template>
  <div class="card">
    <slot />

    <template v-if="$slots.footer">
      <div class="footer">
        <slot name="footer" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card {
  padding: 25px 28px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 56px 0 rgba(23, 25, 51, 0.05),
    0 37px 72px 0 rgba(212, 212, 212, 0.03);
  background-color: #fff;
}

.footer {
  position: relative;
  margin-top: 30px;
  padding-top: 18px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -0.75rem;
    right: -0.75rem;
    border-top: $divider-border;
  }
}
</style>
