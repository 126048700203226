
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { PageContent, EmptyState } from '@bd/components'
import { subscribe } from '@bd/helpers'
import Card from '@bd/agent/components/Card.vue'
import OfferStatisticsChart from '@bd/agent/components/OfferStatisticsChart.vue'
import PeriodDropdown from '@bd/agent/components/PeriodDropdown.vue'
import { OfferDetailsDto } from '@bd/store-modules/types'
import * as Stats from '@bd/agent/types/statistics'
import * as Api from '@bd/api/agent-api/api'
import { OfferStatisticsDto, OfferStatisticsSummaryDto } from '@bd/api'

export default defineComponent({
  components: {
    PageContent,
    PeriodDropdown,
    OfferStatisticsChart,
    Card,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const period = shallowRef<Stats.Period>(1)
    const statsData = shallowRef<OfferStatisticsDto>()
    const summaryData = shallowRef<OfferStatisticsSummaryDto>()

    const offerId = computed(() => {
      return +route.params.offerId as OfferDetailsDto['id']
    })

    const isLoading = computed(() => {
      return !statsData.value || !summaryData.value
    })

    watchEffect((onCleanup) => {
      const startDate = Stats.startDateForPeriod(period.value)
      const statisticsDataResolution = Stats.resolutionForPeriod(period.value)
      const promise = Api.offerStatistics({
        offerId: offerId.value,
        statisticsDataResolution,
        startDate,
      })

      const cancel = subscribe(promise, (dto) => {
        statsData.value = dto.data
      })

      onCleanup(() => cancel())
    })

    watchEffect((onCleanup) => {
      const promise = Api.offerStatisticsSummary(offerId.value)
      const cancel = subscribe(promise, (dto) => {
        summaryData.value = dto.data
      })
      onCleanup(() => cancel())
    })

    return {
      t,
      summaryData,
      statsData,
      period,
      isLoading,
    }
  },
})
