<template>
  <Chart
    type="line"
    :data="chartData"
    :options="chartOptions"
    :plugins="chartPlugins"
    :width="4"
    :height="3"
  />
</template>

<script lang="ts">
import { OfferStatisticsDto } from '@bd/api'
import { parseLocalIsoDate } from '@bd/helpers'
import { ChartData, ChartOptions, ScriptableContext, Plugin } from 'chart.js'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { Chart, DATE_FORMAT } from '@bd/components'

const borderColorGradient = (context: ScriptableContext<'line'>) => {
  const { ctx, chartArea } = context.chart

  if (!chartArea) return 'black'
  const { left, right } = chartArea
  const gradient = ctx.createLinearGradient(left, 0, right, 0)
  gradient.addColorStop(0.23, '#2cb3a3')
  gradient.addColorStop(0.5, '#e78a1f')
  return gradient
}

const chartOptions: ChartOptions = {
  responsive: true,
  interaction: { intersect: false, mode: 'index' },
  scales: {
    x: {
      ticks: { padding: 20 },
      grid: {
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
    y: {
      min: 0,
      suggestedMax: 10,
      ticks: { padding: 20 },
      grid: {
        drawBorder: false,
        drawTicks: false,
      },
    },
  },
  datasets: {
    line: {
      clip: 10,

      cubicInterpolationMode: 'monotone',
      borderWidth: 4,
      borderColor: borderColorGradient,
      borderJoinStyle: 'round',
      borderCapStyle: 'round',

      pointRadius: 0,
      pointHitRadius: 10,
      pointBorderWidth: 0,

      pointHoverRadius: 7.5,
      pointHoverBorderWidth: 5.5,
      pointHoverBorderColor: '#222946',
      pointHoverBackgroundColor: '#fff',
    },
  },
  plugins: {
    tooltip: {
      enabled: true,
      boxWidth: -3,
      boxHeight: 0,
      padding: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 8,
      },
      cornerRadius: 10,
      backgroundColor: '#222946',
      usePointStyle: true,
      xAlign: 'center',
      yAlign: 'bottom',
      bodySpacing: 15,
      bodyFont: { size: 15.3 },
      bodyAlign: 'center',
      callbacks: {
        title: () => '',
      },
    },
  },
}

const xScaleLinePlugin: Plugin<'line'> = {
  id: 'xScaleLinePlugin',
  beforeTooltipDraw(chart, { tooltip }) {
    const { scales, ctx } = chart
    const scaleAt = scales.x.position
    const mult = scaleAt === 'top' ? -1 : 1
    const drawFrom = scaleAt === 'top' ? scales.x.bottom : scales.x.top

    ctx.save()
    for (const { element } of tooltip.dataPoints || []) {
      if (!element.active) continue
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { hoverRadius = 0, hoverBorderWidth = 0 } = element.options as any
      const drawTo = element.y + mult * (hoverRadius + hoverBorderWidth)
      if (scaleAt === 'top' ? drawTo <= drawFrom : drawTo >= drawFrom) continue

      ctx.beginPath()
      ctx.moveTo(element.x, drawFrom)
      ctx.lineTo(element.x, drawTo)
      ctx.lineWidth = 2.5
      ctx.setLineDash([10, 5])
      ctx.strokeStyle = '#9398a5'
      ctx.stroke()
    }
    ctx.restore()
  },
}

export default defineComponent({
  props: {
    stats: {
      type: Object as PropType<OfferStatisticsDto>,
      required: true,
    },
  },
  components: {
    Chart,
  },
  setup(props) {
    const { locale } = useI18n()

    const chartData = computed<ChartData>(() => {
      const dayMonthFormat = new Intl.DateTimeFormat(
        locale.value,
        DATE_FORMAT.offerStatisticsChart,
      )

      const data = props.stats.offerStatisticsInPeriods.map((periodDto) => {
        return periodDto.numberOfViews
      })

      return {
        datasets: [{ data }],
        labels: props.stats.offerStatisticsInPeriods.map((periodDto) => {
          const start = parseLocalIsoDate(periodDto.startDate)
          const end = parseLocalIsoDate(periodDto.endDate)
          const startFmt = dayMonthFormat.format(start)
          const endFmt = dayMonthFormat.format(end)
          return `${startFmt} - ${endFmt}`
        }),
      }
    })

    return {
      chartOptions,
      chartData,
      chartPlugins: [xScaleLinePlugin],
    }
  },
})
</script>
