
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Listbox from 'primevue/listbox'

import { DropdownButton } from '@bd/components'
import { PeriodOption, Period, PERIODS } from '../types/statistics'

export default defineComponent({
  props: {
    modelValue: [String, Number] as PropType<Period>,
  },
  emits: ['update:modelValue'],
  components: {
    DropdownButton,
    Listbox,
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const available: PeriodOption[] = PERIODS.map((months) => {
      return { months, label: t(`statistics.periods.months_${months}`) }
    })

    const selected = computed(() => {
      const found = available.find((p) => p.months === props.modelValue)
      return found || available[0]
    })

    const onSelection = (period: PeriodOption) => {
      emit('update:modelValue', period.months)
    }

    return {
      available,
      selected,
      onSelection,
    }
  },
})
