<template>
  <PageContent class="statistics-content">
    <div class="grid">
      <div class="cell cell--interest">
        <h1 class="cell__header h-1">
          {{ t('statistics.offer_interest') }}
        </h1>

        <div class="vertical-cards">
          <Card>
            <h5 class="h-5 card__header">
              {{ t('statistics.last_week_views') }}
            </h5>

            <div class="card__body">
              <img :src="require('@bd/assets/icons/views-count.svg')" />
              <span class="plus">+</span>
              <span class="text--xl">
                {{ summaryData?.numberOfViews || 0 }}
              </span>
            </div>

            <template #footer>
              <div class="text--muted card__footer">
                {{ t('statistics.total_views') }}
                <span class="text--lg color--views">
                  {{ summaryData?.totalViews || 0 }}
                </span>
              </div>
            </template>
          </Card>

          <Card>
            <h5 class="h-5 card__header">
              {{ t('statistics.last_week_inquiries') }}
            </h5>

            <div class="card__body">
              <img :src="require('@bd/assets/icons/inquiries-count.svg')" />
              <span class="plus">+</span>
              <span class="text--xl">
                {{ summaryData?.numberOfInquiries || 0 }}
              </span>
            </div>

            <template #footer>
              <div class="text--muted card__footer">
                {{ t('statistics.total_inquiries') }}
                <span class="text--lg color--inquiries">
                  {{ summaryData?.totalInquiries || 0 }}
                </span>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <div class="cell cell--similar">
        <h1 class="cell__header h-1">
          {{ t('statistics.similar_offers_statistics') }}
        </h1>
        <div class="horizontal-cards">
          <Card>
            <h5 class="card__header text--muted text-center">
              {{ t('statistics.last_week_views') }}
            </h5>
            <div class="card__body">+{{ summaryData?.similarViews || 0 }}</div>
          </Card>

          <Card>
            <h5 class="card__header text--muted text-center">
              {{ t('statistics.last_week_inquiries') }}
            </h5>
            <div class="card__body">
              +{{ summaryData?.similarInquiries || 0 }}
            </div>
          </Card>
        </div>
      </div>

      <div class="cell cell--chart">
        <h1 class="cell__header h-1">
          <div class="cell__header__label">
            {{ t('statistics.periodic_statistics') }}
          </div>
          <PeriodDropdown v-model="period" class="period-dropdown" />
        </h1>
        <Card>
          <OfferStatisticsChart v-if="statsData" :stats="statsData" />
          <EmptyState v-else :title="''" style="margin-top: 2.8rem" />
        </Card>
      </div>
    </div>
  </PageContent>
</template>

<script lang="ts">
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { PageContent, EmptyState } from '@bd/components'
import { subscribe } from '@bd/helpers'
import Card from '@bd/agent/components/Card.vue'
import OfferStatisticsChart from '@bd/agent/components/OfferStatisticsChart.vue'
import PeriodDropdown from '@bd/agent/components/PeriodDropdown.vue'
import { OfferDetailsDto } from '@bd/store-modules/types'
import * as Stats from '@bd/agent/types/statistics'
import * as Api from '@bd/api/agent-api/api'
import { OfferStatisticsDto, OfferStatisticsSummaryDto } from '@bd/api'

export default defineComponent({
  components: {
    PageContent,
    PeriodDropdown,
    OfferStatisticsChart,
    Card,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const period = shallowRef<Stats.Period>(1)
    const statsData = shallowRef<OfferStatisticsDto>()
    const summaryData = shallowRef<OfferStatisticsSummaryDto>()

    const offerId = computed(() => {
      return +route.params.offerId as OfferDetailsDto['id']
    })

    const isLoading = computed(() => {
      return !statsData.value || !summaryData.value
    })

    watchEffect((onCleanup) => {
      const startDate = Stats.startDateForPeriod(period.value)
      const statisticsDataResolution = Stats.resolutionForPeriod(period.value)
      const promise = Api.offerStatistics({
        offerId: offerId.value,
        statisticsDataResolution,
        startDate,
      })

      const cancel = subscribe(promise, (dto) => {
        statsData.value = dto.data
      })

      onCleanup(() => cancel())
    })

    watchEffect((onCleanup) => {
      const promise = Api.offerStatisticsSummary(offerId.value)
      const cancel = subscribe(promise, (dto) => {
        summaryData.value = dto.data
      })
      onCleanup(() => cancel())
    })

    return {
      t,
      summaryData,
      statsData,
      period,
      isLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.statistics-content) {
  @include breakpoint-down(md) {
    height: 100% !important;
  }
}
.grid {
  display: grid;
  grid-template-columns: minmax(0, 4fr) minmax(0, 5fr);
  grid-template-areas:
    'interest chart'
    'similar  chart';
  --grid-cell-gap: 56px;
  row-gap: var(--grid-cell-gap);
  max-width: get-breakpoint(xl);
  margin: 50px auto 0;
  padding-bottom: 50px;
}

.cell--interest {
  grid-area: interest;
}
.cell--similar {
  grid-area: similar;
}
.cell--chart {
  grid-area: chart;
}

.cell--interest,
.cell--similar {
  margin-left: 78px;
  margin-right: fluidBetween($min: 39px, $min-at: lg, $max: 78px, $max-at: xl);
}

.cell--chart {
  margin-left: fluidBetween($min: 39px, $min-at: lg, $max: 78px, $max-at: xl);
  margin-right: 78px;
}

@include breakpoint-down(lg) {
  .grid {
    @include flex($direction: column, $align: stretch);
    margin: 1.5rem 78px 0;
    row-gap: 0;
  }

  .cell {
    margin: 0 0 var(--grid-cell-gap) 0;
  }
}

@include breakpoint-down(md) {
  .grid {
    margin: 1.5rem 1.5rem 0;
    padding-bottom: calc(1.5rem + #{$mobile-navigation-height});
  }

  .cell--chart .card {
    padding: 0;
  }
}

.cell__header {
  min-height: 50px;
  @include flex($justify: space-between, $wrap: wrap);
  line-height: normal;
  margin-bottom: 30px;

  &__label {
    margin: 1rem 0;
    flex: 1 1 auto;
  }

  > *:not(:last-of-type) {
    margin-right: 1rem;
  }
}

.vertical-cards {
  @include flex($direction: column, $align: stretch);
  > *:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .card__body {
    @include flex($justify: flex-start);
  }

  .card__footer {
    @include flex($justify: space-between);
  }
}

.horizontal-cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  .card {
    border-radius: 6px;
    padding: 28px 42px 32px;
  }

  .card__body {
    @include flex();
    font-size: 26px;
    font-weight: 600;
  }
}

.card__header {
  margin-bottom: 25px;
}

.plus {
  font-size: 18px;
  color: $port-gore;
  font-weight: 600;
  margin-left: 13px;
  margin-right: 4px;
}
.period-dropdown {
  flex: 1 1 auto;
}

// utils
.color--views {
  color: $zest;
}

.color--inquiries {
  color: $jungle-green;
}

.text--lg {
  font-size: 22px;
  font-weight: 500;
}

.text--xl {
  font-size: 40px;
}

.text--muted {
  color: $manatee;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.33;
}
</style>
