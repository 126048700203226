<template>
  <DropdownButton class="p-button-secondary">
    <Button class="p-button-secondary dropdown-btn px-4">
      <span class="label">{{ selected.label }}</span>
      <i class="pi pi-chevron-down ml-3"></i>
    </Button>

    <template #content>
      <Listbox
        :modelValue="selected"
        @update:modelValue="onSelection"
        :options="available"
        optionLabel="label"
      />
    </template>
  </DropdownButton>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Listbox from 'primevue/listbox'

import { DropdownButton } from '@bd/components'
import { PeriodOption, Period, PERIODS } from '../types/statistics'

export default defineComponent({
  props: {
    modelValue: [String, Number] as PropType<Period>,
  },
  emits: ['update:modelValue'],
  components: {
    DropdownButton,
    Listbox,
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const available: PeriodOption[] = PERIODS.map((months) => {
      return { months, label: t(`statistics.periods.months_${months}`) }
    })

    const selected = computed(() => {
      const found = available.find((p) => p.months === props.modelValue)
      return found || available[0]
    })

    const onSelection = (period: PeriodOption) => {
      emit('update:modelValue', period.months)
    }

    return {
      available,
      selected,
      onSelection,
    }
  },
})
</script>

<style lang="scss" scoped>
.dropdown-btn {
  width: 100%;
  height: 50px;
}
.label {
  flex: 1 1 auto;
  text-align: left;
}
</style>
